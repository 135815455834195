<template>
  <div>
    <b-alert
      v-if="neZakljucenCas && (typeof this.$route.params.id === 'undefined')"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        {{ $t('PostojiNezakljucenCas') }}
      </h4>
      <div class="alert-body">
        <span>{{ $t('DaBiSteMogliDaDodateNoviCasPotrrebnoJeDaSviPrethodniBuduZakljuceni') }}</span>
      </div>
    </b-alert>
    <router-link
      v-if="neZakljucenCas && (typeof this.$route.params.id === 'undefined')"
      :to="{
        name: 'instruktori-casovi-prakticne-obuke'
      }"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        {{ $t('Casovi') }}
      </b-button>
    </router-link>
    <b-row
      v-if="(!neZakljucenCas && (typeof this.$route.params.id === 'undefined')) || (typeof this.$route.params.id !== 'undefined')"
      class="match-height"
    >
      <b-col
        lg="12"
        md="6"
      >
        <b-card
          no-body
          class="card-payment"
        >
          <b-card-header>
            <b-card-title>{{ $t(title) }}</b-card-title>
            <b-card-title class="text-primary">
              {{ tekuciDatum }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- form -->
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('DatumCasa')"
                    label-for="datum_casa"
                    class="mb-2"
                  >
                    <b-form-datepicker
                      id="datum_casa"
                      v-model="datum"
                      :placeholder="$t('DatumCasa')"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :locale="this.$i18n.locale"
                      :hide-header="hideHeader"
                      today-button
                      reset-button
                      close-button
                      show-decade-nav
                      :label-today-button="$t('Danas')"
                      :label-reset-button="$t('Ponisti')"
                      :label-close-button="$t('Zatvori')"
                      :label-prev-year="$t('PrethodnaGodina')"
                      :label-prev-month="$t('PrethodniMesec')"
                      :label-next-year="$t('SledecaGodina')"
                      :label-next-month="$t('SledeciMesec')"
                      :label-current-month="$t('TekuciMesec')"
                      :label-prev-decade="$t('MinusDesetGodina')"
                      :label-next-decade="$t('PlusDesetGodina')"
                      label-help=""
                      no-flip
                    />
                    <small
                      v-if="formErrors['datum']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['datum'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('Kandidat')"
                    label-for="kandidat"
                    class="mb-2"
                  >
                    <v-select
                      v-model="kandidat"
                      label="text"
                      :options="kandidati"
                      :placeholder="$t('IzaberiteKandidata')"
                      @input="proveriKandidata"
                    />
                    <small
                      v-if="Object.keys(izabraniKandidat).length !== 0"
                      class="text-danger"
                    >
                      <b-badge
                        v-if="izabraniKandidat.obavestenja.koristi_pomagala"
                        variant="warning"
                      >
                        {{ $t('KoristiPomagala') }}
                      </b-badge>
                      <b-badge
                        v-if="izabraniKandidat.provera.obavezna_prva_pomoc"
                        variant="danger"
                      >
                        {{ $t('ObaveznaPrvaPomoc') }}
                      </b-badge>
                      <b-badge
                        v-if="!izabraniKandidat.provera.vazi_lekarsko"
                        variant="danger"
                      >
                        {{ $t('NeVaziLekarsko') }}
                      </b-badge>
                      <b-badge
                        v-if="!izabraniKandidat.provera.vazi_teorijski_ispit"
                        variant="danger"
                      >
                        {{ $t('NeVaziTI') }}
                      </b-badge>
                    </small>
                    <small
                      v-if="formErrors['kandidat_id']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['kandidat_id'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('Vozilo')"
                    label-for="vozilo"
                    class="mb-2"
                  >
                    <v-select
                      v-model="vozilo"
                      label="text"
                      :options="vozila"
                      :placeholder="$t('IzaberiteVozilo')"
                      @input="proveriVozilo"
                    />
                    <small
                      v-if="Object.keys(izabranoVozilo).length !== 0"
                      class="text-danger"
                    >
                      <b-badge
                        v-if="izabranoVozilo.provera.istekla_registracija"
                        variant="danger"
                      >
                        {{ $t('IsteklaRegistracija') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.provera.istekao_tehnicki"
                        variant="danger"
                      >
                        {{ $t('IstekaoTehnicki') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.obavestenja.istice_prva_pomoc"
                        variant="warning"
                      >
                        {{ $t('IsticePrvaPomoc') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.obavestenja.istice_registracija"
                        variant="warning"
                      >
                        {{ $t('IsticeRegistracija') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.obavestenja.istice_servis_tahografa"
                        variant="warning"
                      >
                        {{ $t('IsticeServisTahografa') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.obavestenja.istice_tehnicki"
                        variant="warning"
                      >
                        {{ $t('isticeTehnicki') }}
                      </b-badge>
                      <b-badge
                        v-if="izabranoVozilo.obavestenja.istice_vaznost_PP_aparata"
                        variant="warning"
                      >
                        {{ $t('IsticeVaznostPPAparata') }}
                      </b-badge>
                    </small>
                    <small
                      v-if="formErrors['vozilo_id']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['vozilo_id'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="8">
                  <b-form-group
                    :label="$t('KodCasa')"
                    label-for="kod_casa"
                    class="mb-2"
                  >
                    <v-select
                      v-model="kod_casa"
                      label="text"
                      :options="kodoviPrakticneObuke"
                      :placeholder="$t('KodCasa')"
                      @input="proveriKodCasa"
                    />
                    <small>
                      <b-badge
                        v-if="provera_vn.status === 'greska'"
                        variant="danger"
                      >
                        {{ provera_vn.text }}
                      </b-badge>
                      <b-badge
                        v-if="provera_vremena_koda_casa.status === 'greska'"
                        variant="danger"
                      >
                        {{ provera_vremena_koda_casa.text }}
                      </b-badge>
                    </small>
                    <small
                      v-if="formErrors['kod_casa']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['kod_casa'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('VanNaselja')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="van_naselja"
                      switch
                      @input="proveriKodCasa"
                    >
                      {{ $t("VN") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('Opterecenje')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="opterecenje"
                      switch
                    >
                      {{ $t("O") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="10">
                  <b-form-group
                    :label="$t('VremePocetkaCasa')"
                    label-for="vreme_pocetka"
                    class="mb-2"
                  >
                    <flat-pickr
                      id="vreme_pocetka"
                      v-model="vreme_pocetka"
                      class="form-control"
                      :config="configTimePicker"
                      @on-close="promenjenPocetakCasa()"
                    />
                    <small
                      v-if="formErrors['vreme_pocetka']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['vreme_pocetka'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('Dvocas')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="dvocas"
                      switch
                      @change="promenjenPocetakCasa()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('VremeZavrsetkaCasa')"
                    label-for="vreme_zavrsetka"
                    class="mb-2"
                  >
                    <flat-pickr
                      id="vreme_zavrsetka"
                      v-model="vreme_zavrsetka"
                      class="form-control"
                      :config="configTimePicker"
                    />
                    <small
                      v-if="formErrors['vreme_zavrsetka']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['vreme_zavrsetka'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('StanjeNaPutometruPocetakCasa')"
                    label-for="pocetno_stanje_na_putometru"
                  >
                    <b-form-input
                      id="pocetno_stanje_na_putometru"
                      v-model="pocetno_stanje_na_putometru"
                      :placeholder="$t('StanjeNaPutometruPocetakCasa')"
                      autocomplete="off"
                      value=""
                      @change="proveriPutometar"
                    />
                    <small>
                      <b-badge
                        v-if="provera_putometra.status === 'greska'"
                        variant="danger"
                      >
                        {{ provera_putometra.text }}
                      </b-badge>
                    </small>
                    <small
                      v-if="formErrors['pocetno_stanje_na_putometru']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['pocetno_stanje_na_putometru'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('StanjeNaPutometruKrajCasa')"
                    label-for="krajnje_stanje_na_putometru"
                  >
                    <b-form-input
                      id="krajnje_stanje_na_putometru"
                      v-model="krajnje_stanje_na_putometru"
                      :placeholder="$t('StanjeNaPutometruKrajCasa')"
                      autocomplete="off"
                      value=""
                    />
                    <small
                      v-if="formErrors['krajnje_stanje_na_putometru']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['krajnje_stanje_na_putometru'][0]) }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                >
                  <b-button-group
                    size="sm"
                  >
                    <b-button
                      :disabled="!kandidatOk || !voziloOk || !kodCasaOk || !stanjeNaPutometruOk"
                      variant="primary"
                      @click="snimiCas('snimljen')"
                    >
                      {{ $t('Snimi') }}
                    </b-button>
                    <b-button
                      :disabled="!kandidatOk || !voziloOk || !kodCasaOk || !stanjeNaPutometruOk"
                      variant="outline-primary"
                      @click="snimiCas('zakljucen')"
                    >
                      {{ $t('ZakljuciCas') }}
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      @click="resetForme"
                    >
                      {{ $t('ResetujFormu') }}
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-form>
            <!--/ form -->
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BFormGroup, BForm, BFormDatepicker, BFormInput, BFormCheckbox, BButton, BButtonGroup, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    vSelect,
    flatPickr,
    BBadge,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tekuciDatum: '',
      kandidati: [{ value: '', text: this.$t('IzaberiteKandidata') }],
      vozila: [{ value: '', text: this.$t('IzaberiteVozilo') }],
      data: {},
      hideHeader: true,
      datum: '',
      kandidat: [{ value: '', text: this.$t('IzaberiteKandidata') }],
      vozilo: '',
      kod_casa: '',
      van_naselja: false,
      opterecenje: false,
      dvocas: false,
      vreme_pocetka: '',
      vreme_zavrsetka: '',
      pocetno_stanje_na_putometru: '',
      krajnje_stanje_na_putometru: '',
      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        disableMobile: true,
        minuteIncrement: 1,
      },
      kodoviPrakticneObuke: [
        { value: 'P', text: 'P' },
        { value: 'SL', text: 'SL' },
        { value: 'SRJ', text: 'SRJ' },
        { value: 'K', text: 'K' },
        { value: 'KP', text: 'KP' },
      ],
      formErrors: [],
      izabraniKandidat: {},
      izabranoVozilo: {},
      provera_vn: {
        status: 'OK',
        text: '',
      },
      provera_vremena_koda_casa: {
        status: 'OK',
        text: '',
      },
      provera_putometra: {
        status: 'OK',
        text: '',
      },
      kandidatOk: false,
      voziloOk: false,
      kodCasaOk: false,
      vremePocetkaCasaOk: false,
      stanjeNaPutometruOk: false,
      title: '',
      neZakljucenCas: false,
      status: '',
      odobrena_promena: false,
    }
  },
  watch: {
    $route() {
      this.resetForme()
    },
  },
  mounted() {
    this.vreme_pocetka = moment(new Date()).format('H:m')
    this.vreme_zavrsetka = moment(new Date()).add(45, 'minutes').format('H:m')
    this.datum = moment(new Date()).format('YYYY-MM-DD')
    this.danasnjiDatum()
    this.pokupiListu()
  },
  methods: {
    danasnjiDatum() {
      this.tekuciDatum = moment(new Date()).format('DD.MM.YYYY.')
    },
    pokupiListu() {
      this.$http.get('/instruktori/unos-casa').then(response => {
        this.neZakljucenCas = response.data.neZakljucenCas
        Object.entries(response.data.kandidati.kandidatiSaKojimaJeRadio).forEach(value => {
          this.kandidati.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime} - ${value[1].idkandidata}` })
        })
        Object.entries(response.data.kandidati.ostaliKandidati).forEach(value => {
          this.kandidati.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime} - ${value[1].idkandidata}` })
        })
        Object.entries(response.data.vozila).forEach(value => {
          this.vozila.push({ value: value[1].id, text: `${value[1].marka} ${value[1].tip} - ${value[1].regbroj}` })
        })
        if (typeof this.$route.params.id !== 'undefined') {
          this.title = this.$i18n.t('IzmeniCas')
          this.$http.get(`/instruktori/casovi-prakticne-obuke/${this.$route.params.id}/edit`).then(res => {
            this.status = res.data.status
            this.odobrena_promena = res.data.odobrena_promena
            this.datum = res.data.datum
            const [selektovaniKandidat] = this.kandidati.filter(e => e.value === res.data.kandidat_id)
            this.kandidat = selektovaniKandidat
            const [selektovanoVozilo] = this.vozila.filter(e => e.value === res.data.vozilo_id)
            this.vozilo = selektovanoVozilo
            const [selektovaniKodCasa] = this.kodoviPrakticneObuke.filter(e => e.value === res.data.kod_casa)
            this.kod_casa = selektovaniKodCasa
            this.van_naselja = res.data.van_naselja
            this.opterecenje = res.data.opterecenje
            this.vreme_pocetka = res.data.vreme_pocetka
            this.vreme_zavrsetka = res.data.vreme_zavrsetka
            this.dvocas = res.data.dvocas
            this.pocetno_stanje_na_putometru = res.data.pocetno_stanje_na_putometru
            this.krajnje_stanje_na_putometru = res.data.krajnje_stanje_na_putometru
            this.proveriKodCasa()
            this.proveriKandidata()
            this.proveriVozilo()
            this.proveriPutometar()
          })
        } else {
          this.title = this.$i18n.t('NoviCas')
        }
      })
    },
    promenjenPocetakCasa() {
      const brojMinuta = this.dvocas ? 90 : 45
      this.vreme_zavrsetka = moment(this.vreme_pocetka, 'H:m').add(brojMinuta, 'minutes').format('H:m')
      this.proveriKodCasa()
    },
    snimiCas(status = 'snimljen') {
      this.data = {
        status,
        datum: this.datum,
        kandidat_id: this.kandidat.value,
        vozilo_id: this.vozilo.value,
        kod_casa: this.kod_casa.value,
        van_naselja: this.van_naselja,
        opterecenje: this.opterecenje,
        dvocas: this.dvocas,
        vreme_pocetka: this.vreme_pocetka,
        vreme_zavrsetka: this.vreme_zavrsetka,
        pocetno_stanje_na_putometru: this.pocetno_stanje_na_putometru,
        krajnje_stanje_na_putometru: this.krajnje_stanje_na_putometru,
        odobrena_promena: 0,
        zahtevana_promena: 0,
      }
      if (typeof this.$route.params.id !== 'undefined') {
        this.$http.put(`/instruktori/casovi-prakticne-obuke/${this.$route.params.id}`, this.data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('CasJeSnimljen'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.$http.post('/instruktori/cas-prakticne-obuke', this.data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('CasJeSnimljen'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      }
      this.pokupiListu()
    },
    resetForme() {
      this.formErrors = []
      this.datum = ''
      this.kandidat = { value: '', text: this.$t('IzaberiteKandidata') }
      this.vozilo = { value: '', text: this.$t('IzaberiteVozilo') }
      this.kod_casa = ''
      this.van_naselja = false
      this.opterecenje = false
      this.vreme_pocetka = ''
      this.vreme_zavrsetka = ''
      this.pocetno_stanje_na_putometru = ''
      this.krajnje_stanje_na_putometru = ''
      this.dvocas = false
      this.vreme_pocetka = moment(new Date()).format('H:m')
      this.vreme_zavrsetka = moment(new Date()).add(45, 'minutes').format('H:m')
      this.provera_vn = {
        status: 'OK',
        text: '',
      }
      this.provera_vremena_koda_casa = {
        status: 'OK',
        text: '',
      }
      this.provera_putometra = {
        status: 'OK',
        text: '',
      }
      this.provera_krajnjeg_stanja_putometra = {
        status: 'OK',
        text: '',
      }
      this.izabraniKandidat = {}
    },
    proveriKandidata() {
      if (this.kandidat !== null && this.kandidat.value !== '') {
        this.$http.get(`/instruktori/unos-casa/provera-kandidat/${this.kandidat.value}`)
          .then(response => {
            this.izabraniKandidat = response.data
            if (this.izabraniKandidat.provera.obavezna_prva_pomoc || !this.izabraniKandidat.provera.vazi_lekarsko || !this.izabraniKandidat.provera.vazi_teorijski_ispit) {
              this.kandidatOk = false
            } else {
              this.kandidatOk = true
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('KandidatJeProveren'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
            this.kandidatOk = false
          })
      } else {
        this.izabraniKandidat = {}
      }
    },
    proveriVozilo() {
      if (this.vozilo !== null && this.vozilo.value !== '') {
        this.$http.get(`/instruktori/unos-casa/provera-vozilo/${this.vozilo.value}`)
          .then(response => {
            this.izabranoVozilo = response.data
            if (this.izabranoVozilo.istekao_tehnicki || this.izabranoVozilo.istekla_registracija) {
              this.voziloOk = false
            } else {
              this.voziloOk = true
            }
            this.proveriPutometar()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('VoziloJeProvereno'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.izabranoVozilo = {}
        this.proveriPutometar()
      }
    },
    proveriKodCasa() {
      if (this.kod_casa !== null && this.kod_casa.value !== '') {
        if (this.kod_casa.value === 'P' && this.van_naselja === true) {
          this.provera_vn = {
            status: 'greska',
            text: this.$i18n.t('KodPNeMozeVanNaselja'),
          }
          this.kodCasaOk = false
        } else if (this.kod_casa.value === 'KP' && this.van_naselja === true) {
          this.provera_vn = {
            status: 'greska',
            text: this.$i18n.t('KodKPNeMozeVanNaselja'),
          }
          this.kodCasaOk = false
        } else {
          this.provera_vn = {
            status: 'OK',
            text: '',
          }
          this.kodCasaOk = true
        }
      } else {
        this.provera_vn = {
          status: 'OK',
          text: '',
        }
        this.kodCasaOk = true
      }

      if (this.kod_casa !== null && this.kod_casa.value !== '' && this.kod_casa.value === 'P') {
        const vreme = `${this.datum} ${this.vreme_pocetka}`
        this.$http.post('/instruktori/unos-casa/provera-vremena-koda-casa', { vreme })
          .then(response => {
            if (response.data.provera === true) {
              this.provera_vremena_koda_casa = {
                status: 'greska',
                text: this.$i18n.t('KodKPNeMozeNocu'),
              }
              this.kodCasaOk = false
            } else {
              this.provera_vremena_koda_casa = {
                status: 'OK',
                text: '',
              }
              this.kodCasaOk = true
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('KodCasaJeProveren'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.provera_vremena_koda_casa = {
          status: 'OK',
          text: '',
        }
      }
    },
    proveriPutometar() {
      if (Object.keys(this.izabranoVozilo).length !== 0) {
        if (this.pocetno_stanje_na_putometru < this.izabranoVozilo.obavestenja.stanje_putometar) {
          this.provera_putometra = {
            status: 'greska',
            text: this.$i18n.t('PocetnoStanjeMoraBitiVece'),
          }
          this.stanjeNaPutometruOk = false
        } else {
          this.provera_putometra = {
            status: 'OK',
            text: '',
          }
          this.stanjeNaPutometruOk = true
        }
      } else {
        this.provera_putometra = {
          status: 'greska',
          text: this.$i18n.t('IzaberiteVozilo'),
        }
        this.stanjeNaPutometruOk = true
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
